export const ScrollToTop = () => {
    window.scrollTo(0, 0);
};

export const StoreScrollPosition = (pathname) => {
    if (!!pathname) {
        const scrollY = window.scrollY;
        sessionStorage.setItem(pathname, scrollY);
        // console.log('Store:', { [pathname]: scrollY });
    } else {
        console.log('No key to store scroll position');
    }
};

export const RestoreScrollPosition = (pathname) => {
    const scrollY = parseFloat(sessionStorage.getItem(pathname));
    if (typeof scrollY == 'number') {
        console.log('Restore:', { [pathname]: scrollY });
        window.scrollTo(0, scrollY);
    }
};
